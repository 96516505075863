<template>
    <div class="main-wrapper">
        <el-scrollbar class="table">
            <!--商品文字撰写-->
            <el-table :data="goodsTextList" v-if="moduleId === 8" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px" height="1%">
                <el-table-column prop="module" label="考试内容" width="200px"></el-table-column>
                <el-table-column label="提交答案（点击商品查看详情）" align="left" width="380px">
                    <template slot-scope="scope">
                        <router-link class="answer" :to="{path: '/teacher/goods/detail', query: { g_id: goods_id }}" target="_blank">
                            <img :src="scope.row.goods_images" alt="">
                            <span class="text">{{scope.row.goods_title}}</span>
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="search_num" label="搜索人数" align="center"></el-table-column>
                <el-table-column prop="click_rate" label="点击率" align="center"></el-table-column>
                <el-table-column prop="student_click_num" label="学生点击量" align="center"></el-table-column>
                <el-table-column prop="full_score_hits" label="满分点击量" align="center"></el-table-column>
                <el-table-column prop="score" :label="`得分（${total_score}分）`" align="center" width="150px"></el-table-column>
            </el-table>
            <!--商品拍摄与处理-->
            <el-table :data="goodsShootList" v-if="moduleId === 9" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="109px">
                <el-table-column prop="module" label="考试内容"></el-table-column>
                <el-table-column label="学生提交答案" align="center">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="success" :underline="false" @click="viewGoodsImg" class="view-detail">查看详情</el-link>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="score" :label="`得分（${total_score}分）`" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="success" :underline="false" @click="scoreDetailGoods(1)" class="view-detail">评分详情</el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!--商品视频拍摄与处理-->
            <el-table :data="goodsVideoShootList" v-if="moduleId === 10" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="109px">
                <el-table-column prop="module" label="考试内容"></el-table-column>
                <el-table-column label="学生提交答案" align="center">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="success" :underline="false" @click="downloadFile" class="view-detail">下载文件</el-link>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="score" :label="`得分（${total_score}分）`" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="link-box">
                            <el-link type="success" :underline="false" @click="scoreDetailView(2)" class="view-detail">评分详情</el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>
        <el-dialog title="商品主图" :visible.sync="goodsImgDialog" width="764px" @close="handleCloseGoodsImgDialog" custom-class="green-dialog goods-img-dialog">
            <div class="upload-file-content">
                <div class="big-img">
                    <img :src="bigImg" alt="">
                </div>
                <div class="small-img">
                    <img :src="item" alt=""
                         v-for="(item, index) in goodsImgsList"
                         @click="viewBigImg(item, index)"
                         :class="{selected: selectIndex === index}">
                </div>
            </div>
        </el-dialog>
        <el-dialog title="评分详情"
                   :visible.sync="scoreDetailDialog" width="830px" custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="goodsScoreList" class="customTable goods-text-table" style="width: 100%; flex: 1;" height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                          :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
                    <el-table-column prop="nickname" label="评委名称" align="center"></el-table-column>
                    <el-table-column prop="score" label="分数" align="center"></el-table-column>
                    <el-table-column prop="update_time" label="评分时间" align="center"></el-table-column>
                </el-table>
                <el-pagination class="pager-center" style="margin-top: 20px;text-align: center"
                               :current-page="scorePages.currentPageNum"
                               :page-size="scorePages.eachPageNum"
                               :total="scorePages.total"
                               layout="prev, pager, next, jumper"
                               @current-change="scoreCurrentChange">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {teacheropGoodsInfoCollectDetail, teacheropTeacherScoreDetail} from '@/utils/apis'

    export default {
        name: "GoodsInfo",
        data() {
            return {
                //商品文字信息撰写
                goodsTextList: [],
                //商品id
                goods_id: '',
                //得分
                total_score: '',
                //商品拍摄与处理
                goodsShootList: [],
                //商品视频拍摄与处理
                goodsVideoShootList: [],
                //下载路径
                download_file: '',
                //商品主图弹窗
                goodsImgDialog: false,
                //商品主图列表
                goodsImgsList: [],
                selectIndex: 0,
                //大图
                bigImg: '',
                //评分详情弹窗
                scoreDetailDialog: false,
                //评分列表
                goodsScoreList: [],
                //分页
                scorePages:{
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                moduleId: null
            }
        },
        created() {
            this.getGoodsInfoList();
        },
        methods: {
            //考试数据
            getGoodsInfoList() {
                let param = {
                    exam_id: this.$route.query.examId,
                    student_id: this.$route.query.id
                }
                teacheropGoodsInfoCollectDetail(param).then((res) => {
                    let obj = {}
                    if (res.data) {
                        this.total_score = res.data.total_score;
                        if (res.data.module === '商品文字信息撰写') {
                            this.moduleId = 8;
                            obj = {
                                module: res.data.module,
                                click_rate: res.data.click_rate,
                                goods_images: res.data.goods_images,
                                goods_title: res.data.goods_title,
                                score: res.data.score,
                                search_num: res.data.search_num,
                                student_click_num: res.data.student_click_num,
                                full_score_hits: res.data.full_score_hits
                            }
                            this.goodsTextList.push(obj);
                            this.goods_id = res.data.id;
                        }
                        if (res.data.module === '商品拍摄与处理') {
                            this.moduleId = 9;
                            obj = {
                                module: res.data.module,
                                score: res.data.score,
                            }
                            this.goodsShootList.push(obj);
                            this.goodsImgsList = res.data.file_path;
                        }
                        if (res.data.module === '商品视频拍摄与处理') {
                            this.moduleId = 10;
                            obj = {
                                module: res.data.module,
                                score: res.data.score,
                            }
                            this.goodsVideoShootList.push(obj);
                            this.download_file = res.data.download_path;
                        }
                    }
                    if (res.data === 8) {
                        this.moduleId = 8;
                        this.goodsTextList = [];
                        this.total_score = 0;
                    }
                    if (res.data === 9) {
                        this.moduleId = 9;
                        this.goodsShootList = [];
                        this.total_score = 0;
                    }
                    if (res.data === 10) {
                        this.moduleId = 10;
                        this.goodsVideoShootList = [];
                        this.total_score = 0;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },
            //查看商品主图
            viewGoodsImg() {
                this.goodsImgDialog = true;
                this.bigImg = this.goodsImgsList[0];
            },
            //查看大图
            viewBigImg(item, index) {
                this.selectIndex = index;
                this.bigImg = item;
            },
            //下载文件
            downloadFile() {
                window.location.href = this.download_file
            },
            //商品拍摄评分详情
            scoreDetailGoods(type) {
                this.scoreDetailDialog = true;
                this.getScoreDetailView(type);
                // this.$router.push({
                //     path: '/examCenter/examData/goodsInfo',
                //     query: {
                //         id: this.$route.query.id,
                //         examId: this.$route.query.examId,
                //         type: 1
                //     }
                // })
            },
            //商品视拍摄评分详情
            scoreDetailView(type) {
                this.scoreDetailDialog = true;
                this.getScoreDetailView(type);
                // this.$router.push({
                //     path: '/examCenter/examData/goodsInfo',
                //     query: {
                //         id: this.$route.query.id,
                //         examId: this.$route.query.examId,
                //         type: 2
                //     }
                // })
            },
            //获取评分列表
            getScoreDetailView(type) {
                let param = {
                    exam_id: this.$route.query.examId,
                    student_id: this.$route.query.id,
                    type: type
                }
                teacheropTeacherScoreDetail(param).then((res) => {
                    this.goodsScoreList = res.data.list;
                    this.scorePages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //关闭商品主图弹窗
            handleCloseGoodsImgDialog() {
                this.goodsImgDialog = false;
                this.selectIndex = 0;
            },
            //分页
            scoreCurrentChange(val) {
                this.scorePages.currentPageNum = val;
                if (this.$route.query.type === 1) {
                    this.getScoreDetailView(1);
                } else {
                    this.getScoreDetailView(2);
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        .table {
            height: 100%;
            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .goods-text-table {
            .answer {
                display: flex;
                align-items: center;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 15px;
                    cursor: pointer;
                }
                .text {
                    flex: 1;
                    width: 1%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
        }
        .link-box {
            .view-detail {
                ::v-deep .el-link--inner {
                    color: #1AB163;
                }
            }
        }
        .goods-img-dialog {
            .upload-file-content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 445px;
                .big-img {
                    width: 444px;
                    height: 444px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .small-img {
                    flex: 1;
                    width: 1%;
                    margin-left: 94px;
                    img {
                        width: 80px;
                        height: 80px;
                        margin-bottom: 15px;
                        border: 2px solid transparent;
                        box-sizing: border-box;
                        &:nth-child(2n-1) {
                            margin-right: 18px;
                        }
                        &:hover {
                            cursor: pointer;
                            border: 2px solid #2DC079;
                        }
                    }
                    .selected {
                        border: 2px solid #2DC079;
                    }
                }
            }
        }
        .goods-score-dialog {
            .upload-file-content {
                display: flex;
                flex-direction: column;
                height: 383px;
            }
        }
    }
</style>